@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
  font-size: 12px;
}

body {
  @apply bg-bg h-full text-1.17;
}

body.dark {
  @apply bg-bg_dark;
}

#root {
  @apply h-full flex flex-col;
}
